/******************************************************************
  Template Name: Sona
  Description: Sona Hotel Html Template
  Author: Colorlib
  Author URI: https://colorlib.com
  Version: 1.0
  Created: Colorlib
******************************************************************/

/*------------------------------------------------------------------
[Table of contents]

1.  Template default CSS
	1.1	Variables
	1.2	Mixins
	1.3	Flexbox
	1.4	Reset
2.  Helper Css
3.  Header Section
4.  Hero Section
5.  About Section
6.  Services Section
7.  Testimonial Section
8.  Blog Section
9.  Contact
10.  Footer Style

-------------------------------------------------------------------*/

/*----------------------------------------*/

/* Template default CSS
/*----------------------------------------*/

html,
body {
  height: 100%;
  font-family: "Cabin", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #111111;
  font-weight: 400;
  font-family: "Lora", serif;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  font-size: 16px;
  font-family: "Cabin", sans-serif;
  color: #6b6b6b;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px 0;
}

img {
  max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: #fff;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
  text-align: center;
  margin-bottom: 22px;
}

.section-title span {
  font-size: 14px;
  color: #dfa974;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.section-title h2 {
  font-size: 44px;
  color: #19191a;
  line-height: 58px;
  margin-top: 10px;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.spad {
  padding-top: 100px;
  padding-bottom: 100px;
}

.spad-2 {
  padding-top: 50px;
  padding-bottom: 70px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
  color: #fff;
}

/* buttons */

.primary-btn {
  display: inline-block;
  font-size: 13px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  position: relative;
}

.primary-btn:after {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background: #dfa974;
  content: "";
}

/* Preloder */

#preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #ffffff;
}

.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
  -webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }

  50% {
    -webkit-transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }

  100% {
    -webkit-transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

.spacial-controls {
  position: fixed;
  width: 111px;
  height: 91px;
  top: 0;
  right: 0;
  z-index: 999;
}

.spacial-controls .search-switch {
  display: block;
  height: 100%;
  padding-top: 30px;
  background: #323232;
  text-align: center;
  cursor: pointer;
}

.search-model {
  /* display: none; */
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  z-index: 99999;
}

.search-model-form {
  padding: 0 15px;
}

.search-model-form input {
  width: 500px;
  font-size: 40px;
  border: none;
  border-bottom: 2px solid #333;
  background: none;
  color: #999;
}

.search-close-switch {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #333;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 28px;
  line-height: 28px;
  top: 30px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*---------------------
  Header
-----------------------*/

.header-section.header-normal .menu-item {
  -webkit-box-shadow: 0px 12px 15px rgba(36, 11, 12, 0.05);
  box-shadow: 0px 12px 15px rgba(36, 11, 12, 0.05);
}

.top-nav {
  border-bottom: 1px solid #e5e5e5;
}

.top-nav .tn-left li {
  list-style: none;
  display: inline-block;
  font-size: 16px;
  color: #19191a;
  font-weight: 500;
  padding: 14px 0 12px;
  margin-right: 64px;
  position: relative;
}

.top-nav .tn-left li:after {
  position: absolute;
  right: -32px;
  top: 0;
  width: 1px;
  height: 100%;
  background: #e5e5e5;
  content: "";
}

.top-nav .tn-left li:last-child {
  margin-right: 0;
}

.top-nav .tn-left li:last-child:after {
  display: none;
}

.top-nav .tn-left li i {
  color: #dfa974;
  margin-right: 4px;
}

.top-nav .tn-right {
  text-align: right;
}

.top-nav .tn-right .top-social {
  display: inline-block;
  margin-right: 35px;
  padding: 13px 0;
}

.top-nav .tn-right .top-social a {
  display: inline-block;
  font-size: 16px;
  color: #19191a;
  margin-left: 15px;
}

.top-nav .tn-right .bk-btn {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  padding: 16px 28px 15px;
  background: #dfa974;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.top-nav .tn-right .language-option {
  display: inline-block;
  margin-left: 30px;
  cursor: pointer;
  padding: 13px 0px 11px;
  position: relative;
}

.top-nav .tn-right .language-option:hover .flag-dropdown {
  top: 40px;
  opacity: 1;
  visibility: visible;
}

.top-nav .tn-right .language-option img {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  margin-right: 10px;
}

.top-nav .tn-right .language-option span {
  font-size: 16px;
  color: #19191a;
  text-transform: uppercase;
  font-weight: 500;
}

.top-nav .tn-right .language-option .flag-dropdown {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100px;
  background: #ffffff;
  display: block;
  padding: 10px 15px;
  -webkit-box-shadow: 0px 9px 10px rgba(25, 25, 26, 0.2);
  box-shadow: 0px 9px 10px rgba(25, 25, 26, 0.2);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 99;
}

.top-nav .tn-right .language-option .flag-dropdown ul li {
  list-style: none;
  text-align: left;
}

.top-nav .tn-right .language-option .flag-dropdown ul li a {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #19191a;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.top-nav .tn-right .language-option .flag-dropdown ul li a:hover {
  color: #dfa974;
}

.menu-item {
  position: relative;
  z-index: 9;
  background: #222736;
}

.menu-item .logo {
  padding: 25px 0;
}

.menu-item .logo a {
  display: inline-block;
}

.menu-item .nav-menu {
  text-align: right;
}

.menu-item .nav-menu .mainmenu {
  display: inline-block;
}

.menu-item .nav-menu .mainmenu li {
  list-style: none;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.menu-item .nav-menu .mainmenu li.active a:after {
  opacity: 1;
}

.menu-item .nav-menu .mainmenu li:hover>a:after {
  opacity: 1;
}

.menu-item .nav-menu .mainmenu li:hover .dropdown {
  top: 77px;
  opacity: 1;
  visibility: visible;
}

.menu-item .nav-menu .mainmenu li a {
  font-size: 16px;
  color: #19191a;
  margin-right: 42px;
  font-weight: 500;
  display: inline-block;
  padding: 27px 0;
  position: relative;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.menu-item .nav-menu .mainmenu li a:after {
  position: absolute;
  left: 0;
  top: 52px;
  width: 100%;
  height: 2px;
  background: #dfa974;
  content: "";
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.menu-item .nav-menu .mainmenu li .dropdown {
  position: absolute;
  left: 0;
  top: 97px;
  width: 180px;
  background: #ffffff;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0px 9px 15px rgba(25, 25, 26, 0.05);
  box-shadow: 0px 9px 15px rgba(25, 25, 26, 0.05);
}

.menu-item .nav-menu .mainmenu li .dropdown li {
  list-style: none;
  display: block;
}

.menu-item .nav-menu .mainmenu li .dropdown li a {
  font-size: 16px;
  color: #19191a;
  display: block;
  text-align: left;
  padding: 8px 15px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.menu-item .nav-menu .mainmenu li .dropdown li a:hover {
  color: #dfa974;
}

.menu-item .nav-menu .mainmenu li .dropdown li a:after {
  display: none;
}

.menu-item .nav-menu .nav-right {
  display: inline-block;
  margin-left: 43px;
  padding: 27px 0px;
}

.menu-item .nav-menu .nav-right i {
  font-size: 16px;
  color: #19191a;
  cursor: pointer;
}

.canvas-open {
  display: none;
}

/* .offcanvas-menu-wrapper {
  display: none;
} */
/*---------------------
  Hero
-----------------------*/

.hero-section {
  position: relative;
  padding-top: 60px;
  padding-bottom: 100px;
}

.hero-section .container {
  position: relative;
  z-index: 5;
}

.hero-text {
  padding-top: 145px;
  text-align: justify;
}

.hero-text h1 {
  font-size: 75px;
  line-height: 90px;
  color: #ffffff;
  margin-bottom: 16px;
}

.hero-text p {
  font-size: 18px;
  color: #ffffff;
  line-height: 28px;
  margin-bottom: 35px;
}

.booking-form {
  background: #ffffff;
  padding: 44px 40px 50px 40px;
  border-radius: 4px;
}

.booking-form h3 {
  color: #19191a;
  margin-bottom: 36px;
}

.booking-form form .check-date {
  position: relative;
  margin-bottom: 15px;
}

.booking-form form .check-date label {
  font-size: 14px;
  color: #707079;
  display: block;
  margin-bottom: 10px;
}

.booking-form form .check-date input {
  width: 100%;
  height: 50px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  font-size: 16px;
  color: #19191a;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 20px;
}

.booking-form form .check-date i {
  color: #dfa974;
  position: absolute;
  right: 18px;
  bottom: 17px;
}

.booking-form form .select-option {
  margin-bottom: 15px;
}

.booking-form form .select-option label {
  font-size: 14px;
  color: #707079;
  display: block;
  margin-bottom: 10px;
}

.booking-form form .select-option .nice-select {
  border-radius: 2px;
  border: 1px solid #ebebeb;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding-left: 20px;
  width: 100%;
  float: none;
}

.booking-form form .select-option .nice-select:after {
  border-bottom: 2px solid #dfa974;
  border-right: 2px solid #dfa974;
  height: 10px;
  margin-top: 0;
  right: 20px;
  width: 10px;
  top: 36%;
}

.booking-form form .select-option .nice-select span {
  font-size: 16px;
  color: #19191a;
  text-transform: uppercase;
  font-weight: 500;
}

.booking-form form .select-option .nice-select .list {
  margin-top: 0;
  width: 100%;
}

.booking-form form button {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid #dfa974;
  border-radius: 2px;
  color: #dfa974;
  font-weight: 500;
  background: transparent;
  width: 100%;
  height: 46px;
  margin-top: 30px;
}

.hero-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.hero-slider.owl-carousel .owl-dots {
  position: absolute;
  left: 0;
  bottom: 23px;
  width: 100%;
  text-align: center;
}

.hero-slider.owl-carousel .owl-dots button {
  height: 6px;
  width: 6px;
  background: #ffffff;
  opacity: 0.5;
  border-radius: 50%;
  margin-right: 10px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.hero-slider.owl-carousel .owl-dots button.active {
  width: 30px;
  border-radius: 50px;
  opacity: 1;
}

.hero-slider.owl-carousel .owl-dots button:last-child {
  margin-right: 0;
}

.hero-slider .owl-stage-outer,
.hero-slider .owl-stage,
.hero-slider .owl-item,
.hero-slider .hs-item {
  height: 100%;
}

/*---------------------
  About US
-----------------------*/

.about-text {
  text-align: center;
  padding: 0 35px;
}

.about-text p {
  color: #595960;
  font-weight: 500;
}

.about-text p.f-para {
  margin-bottom: 10px;
}

.about-text p.s-para {
  margin-bottom: 35px;
}

.about-text .about-btn {
  color: #19191a;
}

.about-pic img {
  min-width: 100%;
}

/*---------------------
  Service Section
-----------------------*/

.services-section {
  padding-bottom: 60px;
  border-top: 1px solid #e5e5e5;
}

.services-section .section-title {
  margin-bottom: 36px;
}

.service-item {
  text-align: center;
  padding: 30px 35px 18px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.service-item:hover {
  background: #dfa974;
}

.service-item:hover i {
  color: #ffffff;
}

.service-item:hover h4 {
  color: #ffffff;
}

.service-item:hover p {
  color: #ffffff;
}

.service-item i {
  display: inline-block;
  color: #dfa974;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.service-item h4 {
  color: #19191a;
  font-size: 22px;
  margin-bottom: 15px;
  margin-top: 10px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.service-item p {
  text-align: justify;
  color: #707079;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/*------------------------
  Home Page Room Section 
-------------------------*/

.hp-room-items {
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
}

.hp-room-items .hp-room-item {
  height: 600px;
  margin-right: -15px;
  position: relative;
  overflow: hidden;
}

.hp-room-items .hp-room-item:hover .hr-text {
  bottom: 135px;
}

.hp-room-items .hp-room-item:hover .hr-text h2 {
  margin-bottom: 30px;
}

.hp-room-items .hp-room-item .hr-text {
  position: absolute;
  left: 45px;
  right: 25px;
  bottom: -250px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.hp-room-items .hp-room-item .hr-text h3 {
  color: #ffffff;
  margin-bottom: 15px;
}

.hp-room-items .hp-room-item .hr-text h2 {
  color: #dfa974;
  font-weight: 700;
  margin-bottom: 50px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.hp-room-items .hp-room-item .hr-text h2 span {
  font-family: "Cabin", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.hp-room-items .hp-room-item .hr-text table {
  margin-bottom: 30px;
}

.hp-room-items .hp-room-item .hr-text table tbody tr td {
  font-size: 16px;
  color: #ffffff;
  line-height: 36px;
}

.hp-room-items .hp-room-item .hr-text table tbody tr td.r-o {
  width: 120px;
}

.hp-room-items .hp-room-item .hr-text .primary-btn:after {
  background: #ffffff;
}

/*---------------------
  Testimonial Slider
-----------------------*/

.testimonial-section {
  background: #f9f9f9;
}

.testimonial-section .section-title {
  margin-bottom: 30px;
}

.testimonial-slider {
  display: flex;
  justify-content: space-between;
}

.testimonial-slider.owl-carousel .owl-item img {
  width: auto;
  margin: 0 auto;
}

.testimonial-slider.owl-carousel .owl-nav button {
  height: 44px;
  width: 44px;
  border: 1px solid #ebebeb;
  font-size: 24px;
  color: #707079;
  background: #ffffff;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  left: -190px;
  top: 50%;
  -webkit-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
  transform: translateY(-6px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.testimonial-slider.owl-carousel .owl-nav button:hover {
  border-color: #19191a;
  color: #19191a;
}

.testimonial-slider.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: -190px;
}

.testimonial-slider .ts-item {
  text-align: center;
  margin: 20px;
  width: 80%;
}

.testimonial-slider .ts-item p {
  font-size: 20px;
  color: #707079;
  line-height: 30px;
  margin-bottom: 50px;
}

.testimonial-slider .ts-item .ti-author {
  margin-bottom: 60px;
}

.testimonial-slider .ts-item .ti-author .rating {
  display: inline-block;
}

.testimonial-slider .ts-item .ti-author .rating i {
  color: #f5b917;
  font-size: 16px;
}

.testimonial-slider .ts-item .ti-author h5 {
  font-size: 20px;
  color: #19191a;
  display: inline-block;
}

/*---------------------
  Blog and Event
-----------------------*/

.blog-section {
  padding-bottom: 70px;
}

.blog-section .section-title {
  margin-bottom: 36px;
}

/*---------------------
  Footer Section
-----------------------*/

.footer-section {
  background: #222736;
}

.footer-section .footer-text {
  padding: 80px 0 30px;
}

.footer-section .footer-text .ft-about {
  margin-bottom: 30px;
}

.footer-section .footer-text .ft-about .logo {
  margin-bottom: 20px;
}

.footer-section .footer-text .ft-about .logo a {
  display: inline-block;
}

.footer-section .footer-text .ft-about p {
  color: #aaaab3;
  margin-bottom: 20px;
}

.footer-section .footer-text .ft-about .fa-social a {
  display: inline-block;
  height: 40px;
  width: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #5a4d48;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-right: 7px;
}

.footer-section .footer-text .ft-about .fa-social a:hover {
  background: #dfa974;
  border-color: #dfa974;
}

.footer-section .footer-text .ft-contact {
  margin-bottom: 30px;
}

.footer-section .footer-text .ft-contact h6 {
  font-size: 14px;
  color: #dfa974;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  font-family: "Cabin", sans-serif;
  margin-bottom: 20px;
}

.footer-section .footer-text .ft-contact ul li {
  font-size: 16px;
  color: #aaaab3;
  line-height: 32px;
  list-style: none;
}

.footer-section .footer-text .ft-newslatter h6 {
  font-size: 14px;
  color: #dfa974;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  font-family: "Cabin", sans-serif;
  margin-bottom: 20px;
}

.footer-section .footer-text .ft-newslatter p {
  color: #aaaab3;
  margin-bottom: 20px;
}

.footer-section .footer-text .ft-newslatter .fn-form {
  position: relative;
}

.footer-section .footer-text .ft-newslatter .fn-form input {
  width: 100%;
  height: 50px;
  border-radius: 2px;
  background: #393d4a;
  border: none;
  padding-left: 20px;
  font-size: 16px;
  color: #707079;
}

.footer-section .footer-text .ft-newslatter .fn-form button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  background: #dfa974;
  color: #ffffff;
  padding: 0 16px;
  height: 50px;
  border: none;
  border-radius: 0 2px 2px 0;
}

.footer-section .copyright-option {
  background: rgba(16, 20, 31, 0.2);
  padding: 20px 0;
}

.footer-section .copyright-option ul li {
  list-style: none;
  display: inline-block;
  margin-right: 34px;
}

.footer-section .copyright-option ul li:last-child {
  margin-right: 0;
}

.footer-section .copyright-option ul li a {
  font-size: 16px;
  color: #aaaab3;
}

.footer-section .copyright-option .co-text {
  font-size: 16px;
  color: #707079;
  text-align: right;
}

/* --------------------------------- Other Pages Styles ----------------------------------- */

/*---------------------
  Breadcrumb Section
-----------------------*/

.breadcrumb-section {
  padding-top: 70px;
  padding-bottom: 80px;
}

.breadcrumb-text {
  text-align: center;
}

.breadcrumb-text h2 {
  font-size: 44px;
  color: #19191a;
  margin-bottom: 12px;
}

.breadcrumb-text .bt-option a {
  display: inline-block;
  font-size: 18px;
  color: #19191a;
  margin-right: 20px;
  position: relative;
  font-weight: 500;
}

.breadcrumb-text .bt-option a:after {
  position: absolute;
  right: -13px;
  top: 1px;
  content: "";
  font-size: 18px;
  font-family: "FontAwesome";
  color: #aaaab3;
}

.breadcrumb-text .bt-option span {
  display: inline-block;
  font-size: 18px;
  color: #aaaab3;
}

/*------------------------
  About Us Page Section
-------------------------*/

.aboutus-page-section {
  padding-top: 0;
  padding-bottom: 70px;
}

.about-page-text {
  margin-bottom: 65px;
}

.about-page-text .ap-title {
  margin-bottom: 30px;
}

.about-page-text .ap-title h2 {
  font-size: 44px;
  color: #19191a;
  margin-bottom: 18px;
}

.about-page-text .ap-title p {
  font-size: 18px;
  color: #707079;
  line-height: 28px;
}

.about-page-text .ap-services li {
  list-style: none;
  font-size: 20px;
  color: #707079;
  line-height: 42px;
}

.about-page-text .ap-services li i {
  color: #dfa974;
  margin-right: 5px;
}

.about-page-services .ap-service-item {
  position: relative;
  height: 420px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.about-page-services .ap-service-item .api-text {
  position: absolute;
  /* left: 0;
  bottom: 40px; */
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(25, 25, 26, 0.479);
  justify-content: center;
  padding: 20px;
}

.about-page-services .ap-service-item .api-text h3 {
  color: #f5b917;
  margin-top: 50%;
  font-size: 30px;
  font-weight: 700;
}

.about-page-services .ap-service-item .api-text p {
  color: #ffffff;
  font-weight: 200;
  /* padding: 10px 0; */
}

/*---------------------
  Video Section
-----------------------*/

.video-section {
  height: 500px;
  padding-top: 140px;
}

.video-section .video-text {
  text-align: center;
}

.video-section .video-text h2 {
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 16px;
}

.video-section .video-text p {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 40px;
}

.video-section .video-text .play-btn {
  display: inline-block;
}

/*---------------------
  Gallery Section
-----------------------*/

.gallery-section {
  padding-bottom: 80px;
}

.gallery-section .section-title {
  margin-bottom: 38px;
}

.gallery-item {
  position: relative;
  height: 279px;
  margin-bottom: 20px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.gallery-item:hover:after {
  opacity: 1;
}

.gallery-item:hover .gi-text {
  opacity: 1;
}

.gallery-item:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 26, 0.3);
  content: "";
  z-index: -1;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.gallery-item.large-item {
  height: 576px;
}

.gallery-item .gi-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.gallery-item .gi-text h3 {
  color: #ffffff;
}

/*---------------------
  Room Section
-----------------------*/

.rooms-section {
  padding-top: 0;
  padding-bottom: 80px;
}

.room-item {
  margin-bottom: 30px;
}

.room-item img {
  min-width: 100%;
}

.room-item .ri-text {
  border: 1px solid #ebebeb;
  border-top: none;
  padding: 24px 24px 30px 28px;
}

.room-item .ri-text h4 {
  color: #19191a;
  margin-bottom: 17px;
}

.room-item .ri-text h3 {
  color: #dfa974;
  font-weight: 700;
  margin-bottom: 14px;
}

.room-item .ri-text h3 span {
  font-family: "Cabin", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #19191a;
}

.room-item .ri-text table {
  margin-bottom: 18px;
}

.room-item .ri-text table tbody tr td {
  font-size: 16px;
  color: #707079;
  line-height: 36px;
}

.room-item .ri-text table tbody tr td.r-o {
  width: 125px;
}

.room-item .ri-text .primary-btn {
  color: #19191a;
}

.room-pagination {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
}

.room-pagination a {
  font-size: 16px;
  color: #707079;
  border: 1px solid #efd4b9;
  border-radius: 2px;
  padding: 7px 13px 5px;
  margin-right: 7px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.room-pagination a:last-child {
  margin-right: 0;
}

.room-pagination a:hover {
  background: #dfa974;
  color: #ffffff;
}

/*-----------------------
  Room Details Section
-------------------------*/

.room-details-section {
  padding-top: 0;
  padding-bottom: 80px;
}

.room-details-section .image-options {
  display: flex;
  justify-content: space-between;
}

.room-details-section .image-options img {
  width: 19.5%;
  cursor: pointer;
}

.room-details-item {
  margin-bottom: 50px;
}

.room-details-item img {
  margin-bottom: 40px;
}

.room-details-item .rd-text .rd-title {
  overflow: hidden;
  margin-bottom: 8px;
}

.room-details-item .rd-text .rd-title h3 {
  color: #19191a;
  float: left;
}

.room-details-item .rd-text .rd-title .rdt-right {
  float: right;
  text-align: right;
}

.room-details-item .rd-text .rd-title .rdt-right .rating {
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 10px;
}

.room-details-item .rd-text .rd-title .rdt-right .rating i {
  color: #f5b917;
}

.room-details-item .rd-text .rd-title .rdt-right a {
  display: inline-block;
  color: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  background: #dfa974;
  padding: 14px 28px 13px;
}

.room-details-item .rd-text h2 {
  color: #dfa974;
  font-weight: 700;
  margin-bottom: 25px;
}

.room-details-item .rd-text h2 span {
  font-family: "Cabin", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #19191a;
}

.room-details-item .rd-text table {
  margin-bottom: 32px;
}

.room-details-item .rd-text table tbody tr td {
  font-size: 16px;
  color: #19191a;
  line-height: 36px;
}

.room-details-item .rd-text table tbody tr td.r-o {
  width: 120px;
  color: #707079;
}

.room-details-item .rd-text p {
  color: #707079;
}

.room-details-item .rd-text p.f-para {
  margin-bottom: 22px;
}

.rd-reviews {
  padding-top: 55px;
  border-top: 1px solid #e5e5e5;
  margin-bottom: 50px;
}

.rd-reviews h4 {
  color: #19191a;
  letter-spacing: 1px;
  margin-bottom: 45px;
}

.rd-reviews .review-item {
  margin-bottom: 32px;
}

.rd-reviews .review-item .ri-pic {
  float: left;
  margin-right: 30px;
}

.rd-reviews .review-item .ri-pic img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.rd-reviews .review-item .ri-text {
  overflow: hidden;
  position: relative;
  padding-left: 30px;
}

.rd-reviews .review-item .ri-text:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background: #e9e9e9;
  content: "";
}

.rd-reviews .review-item .ri-text span {
  font-size: 12px;
  color: #dfa974;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.rd-reviews .review-item .ri-text .rating {
  position: absolute;
  right: 0;
  top: 0;
}

.rd-reviews .review-item .ri-text .rating i {
  color: #f5b917;
}

.rd-reviews .review-item .ri-text h5 {
  color: #19191a;
  margin-top: 4px;
  margin-bottom: 8px;
}

.rd-reviews .review-item .ri-text p {
  color: #707079;
  margin-bottom: 0;
}

.review-add h4 {
  color: #19191a;
  letter-spacing: 1px;
  margin-bottom: 45px;
}

.review-add .ra-form input {
  width: 100%;
  height: 50px;
  border: 1px solid #e5e5e5;
  font-size: 16px;
  color: #aaaab3;
  padding-left: 20px;
  margin-bottom: 25px;
}

.review-add .ra-form input::-webkit-input-placeholder {
  color: #aaaab3;
}

.review-add .ra-form input::-moz-placeholder {
  color: #aaaab3;
}

.review-add .ra-form input:-ms-input-placeholder {
  color: #aaaab3;
}

.review-add .ra-form input::-ms-input-placeholder {
  color: #aaaab3;
}

.review-add .ra-form input::placeholder {
  color: #aaaab3;
}

.review-add .ra-form h5 {
  font-size: 20px;
  color: #19191a;
  margin-bottom: 24px;
  float: left;
  margin-right: 10px;
}

.review-add .ra-form .rating {
  padding-top: 3px;
  display: inline-block;
}

.review-add .ra-form .rating i {
  color: #f5b917;
  font-size: 16px;
}

.review-add .ra-form textarea {
  width: 100%;
  height: 132px;
  border: 1px solid #e5e5e5;
  font-size: 16px;
  color: #aaaab3;
  padding-left: 20px;
  padding-top: 12px;
  margin-bottom: 24px;
  resize: none;
}

.review-add .ra-form textarea::-webkit-input-placeholder {
  color: #aaaab3;
}

.review-add .ra-form textarea::-moz-placeholder {
  color: #aaaab3;
}

.review-add .ra-form textarea:-ms-input-placeholder {
  color: #aaaab3;
}

.review-add .ra-form textarea::-ms-input-placeholder {
  color: #aaaab3;
}

.review-add .ra-form textarea::placeholder {
  color: #aaaab3;
}

.review-add .ra-form button {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 2px;
  background: #dfa974;
  border: none;
  padding: 14px 34px 13px;
  display: inline-block;
}

.room-booking {
  padding: 0 30px 0 40px;
}

.room-booking h3 {
  color: #19191a;
  margin-bottom: 30px;
}

.room-booking form .check-date {
  position: relative;
  margin-bottom: 15px;
}

.room-booking form .check-date label {
  font-size: 14px;
  color: #707079;
  display: block;
  margin-bottom: 10px;
}

.room-booking form .check-date input {
  width: 100%;
  height: 50px;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  font-size: 16px;
  color: #19191a;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 20px;
}

.room-booking form .check-date i {
  color: #dfa974;
  position: absolute;
  right: 18px;
  bottom: 17px;
}

.room-booking form .select-option {
  margin-bottom: 15px;
}

.room-booking form .select-option label {
  font-size: 14px;
  color: #707079;
  display: block;
  margin-bottom: 10px;
}

.room-booking form .select-option .nice-select {
  border-radius: 2px;
  border: 1px solid #ebebeb;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding-left: 20px;
  width: 100%;
  float: none;
}

.room-booking form .select-option .nice-select:after {
  border-bottom: 2px solid #dfa974;
  border-right: 2px solid #dfa974;
  height: 10px;
  margin-top: 0;
  right: 20px;
  width: 10px;
  top: 36%;
}

.room-booking form .select-option .nice-select span {
  font-size: 16px;
  color: #19191a;
  text-transform: uppercase;
  font-weight: 500;
}

.room-booking form .select-option .nice-select .list {
  margin-top: 0;
  width: 100%;
}

.room-booking form .radio-select-div label {
  font-size: 14px;
  color: #707079;
  display: block;
  margin-bottom: 10px;
}

.room-booking form .radio-select-options {
  display: flex;
  justify-content: space-evenly;
}

.room-booking form .radio-select-options input {
  margin-left: 10px;
}

.room-booking form button {
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid #dfa974;
  border-radius: 2px;
  color: #dfa974;
  font-weight: 500;
  background: transparent;
  width: 100%;
  height: 46px;
  margin-top: 30px;
}

/*---------------------
  Blog Section
-----------------------*/

.blog-section.blog-page {
  padding-top: 0;
  padding-bottom: 88px;
}

.blog-item {
  height: 450px;
  position: relative;
  margin-bottom: 30px;
  border-radius: 5px;
}

.blog-item.small-size {
  height: 400px;
}

.blog-item .bi-text {
  position: absolute;
  left: 0;
  bottom: 25px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.blog-item .bi-text .b-tag {
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #dfa974;
  padding: 3px 10px;
  border-radius: 2px;
}

.blog-item .bi-text h4 {
  margin-top: 18px;
  margin-bottom: 18px;
}

.blog-item .bi-text h4 a {
  color: #ffffff;
}

.blog-item .bi-text .b-time {
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.load-more {
  text-align: center;
  padding-top: 30px;
}

.load-more .primary-btn {
  color: #19191a;
}

/*----------------------------
  Blog Details Hero Section
----------------------------*/

.blog-details-hero {
  height: 530px;
  padding-top: 165px;
}

.bd-hero-text {
  text-align: center;
}

.bd-hero-text span {
  font-size: 12px;
  color: #ffffff;
  background: #dfa974;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 2px;
}

.bd-hero-text h2 {
  font-size: 44px;
  color: #ffffff;
  line-height: 54px;
  margin-top: 30px;
  margin-bottom: 12px;
}

.bd-hero-text ul li {
  list-style: none;
  font-size: 14px;
  color: #dfa974;
  margin-right: 35px;
  display: inline-block;
}

.bd-hero-text ul li:last-child {
  margin-right: 0;
}

.bd-hero-text ul li.b-time {
  text-transform: uppercase;
}

/*---------------------
  Blog Details Section
-----------------------*/

.blog-details-section {
  padding-top: 55px;
}

.blog-details-text .bd-title {
  margin-bottom: 52px;
}

.blog-details-text .bd-title p {
  font-size: 18px;
  color: #707079;
  line-height: 30px;
}

.blog-details-text .bd-pic {
  overflow: hidden;
  margin-bottom: 35px;
}

.blog-details-text .bd-pic .bp-item {
  width: calc(33.33% - 10px);
  float: left;
  margin-right: 15px;
}

.blog-details-text .bd-pic .bp-item:last-child {
  margin-right: 0;
}

.blog-details-text .bd-pic .bp-item img {
  min-width: 100%;
}

.blog-details-text .bd-more-text {
  margin-bottom: 50px;
}

.blog-details-text .bd-more-text .bm-item {
  margin-bottom: 30px;
}

.blog-details-text .bd-more-text .bm-item h4 {
  color: #19191a;
  margin-bottom: 20px;
}

.blog-details-text .bd-more-text .bm-item p {
  font-size: 18px;
  color: #707079;
  line-height: 30px;
}

.blog-details-text .tag-share {
  overflow: hidden;
  border-top: 1px solid #ebebeb;
  padding-top: 30px;
  margin-bottom: 75px;
}

.blog-details-text .tag-share .tags {
  float: left;
}

.blog-details-text .tag-share .tags a {
  font-size: 12px;
  color: #707079;
  background: #ebebeb;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-right: 6px;
}

.blog-details-text .tag-share .tags a:hover {
  color: #ffffff;
  background: #dfa974;
}

.blog-details-text .tag-share .social-share {
  float: right;
}

.blog-details-text .tag-share .social-share span {
  display: inline-block;
  font-size: 18px;
  color: #707079;
}

.blog-details-text .tag-share .social-share a {
  display: inline-block;
  font-size: 16px;
  color: #707079;
  margin-left: 30px;
}

.blog-details-text .comment-option {
  margin-bottom: 75px;
}

.blog-details-text .comment-option h4 {
  color: #19191a;
  margin-bottom: 35px;
}

.blog-details-text .comment-option .single-comment-item {
  margin-bottom: 30px;
}

.blog-details-text .comment-option .single-comment-item.first-comment .sc-text {
  padding-left: 22px;
}

.blog-details-text .comment-option .single-comment-item.first-comment .sc-text:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 255px;
  background: #ebebeb;
  content: "";
}

.blog-details-text .comment-option .single-comment-item.reply-comment {
  padding-left: 120px;
  margin-bottom: 52px;
}

.blog-details-text .comment-option .single-comment-item.second-comment .sc-text {
  padding-left: 22px;
}

.blog-details-text .comment-option .single-comment-item.second-comment .sc-text:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100px;
  background: #ebebeb;
  content: "";
}

.blog-details-text .comment-option .single-comment-item .sc-author {
  float: left;
  margin-right: 28px;
}

.blog-details-text .comment-option .single-comment-item .sc-author img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.blog-details-text .comment-option .single-comment-item .sc-text {
  display: table;
  position: relative;
}

.blog-details-text .comment-option .single-comment-item .sc-text span {
  font-size: 12px;
  color: #dfa974;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.blog-details-text .comment-option .single-comment-item .sc-text h5 {
  font-size: 20px;
  color: #19191a;
  margin-top: 4px;
  margin-bottom: 8px;
}

.blog-details-text .comment-option .single-comment-item .sc-text p {
  color: #707079;
  margin-bottom: 18px;
}

.blog-details-text .comment-option .single-comment-item .sc-text a {
  display: inline-block;
  color: #19191a;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 24px;
  border: 1px solid #f9eee3;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-right: 10px;
}

.blog-details-text .comment-option .single-comment-item .sc-text a:hover {
  background: #dfa974;
  color: #ffffff;
  border-color: #dfa974;
}

.blog-details-text .leave-comment h4 {
  color: #19191a;
  margin-bottom: 35px;
}

.blog-details-text .leave-comment .comment-form input {
  width: 100%;
  height: 50px;
  color: #707079;
  font-size: 16px;
  padding-left: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.blog-details-text .leave-comment .comment-form input:focus {
  border-color: #dfa974;
}

.blog-details-text .leave-comment .comment-form textarea {
  width: 100%;
  height: 116px;
  color: #707079;
  font-size: 16px;
  padding-left: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 42px;
  resize: none;
  padding-top: 12px;
}

.blog-details-text .leave-comment .comment-form textarea:focus {
  border-color: #dfa974;
}

.blog-details-text .leave-comment .comment-form button {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 2px;
  background: #dfa974;
  border: none;
  padding: 14px 42px 13px;
  display: inline-block;
  border-radius: 2px;
}

/*---------------------
  Recommend Blog
-----------------------*/

.recommend-blog-section {
  padding-top: 65px;
  padding-bottom: 50px;
}

.recommend-blog-section .section-title {
  margin-bottom: 37px;
}

.recommend-blog-section .section-title h2 {
  margin-top: 0;
}

/*---------------------
  Contact Section
-----------------------*/

.contact-section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.contact-text h2 {
  font-size: 44px;
  color: #19191a;
  margin-bottom: 24px;
}

.contact-text p {
  color: #707079;
}

.contact-text table tbody tr td {
  font-size: 16px;
  color: #19191a;
  line-height: 36px;
  font-weight: 500;
}

.contact-text table tbody tr td.c-o {
  color: #707079;
  width: 75px;
}

.contact-form input {
  width: 100%;
  height: 50px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #aaaab3;
  padding-left: 25px;
  margin-bottom: 28px;
}

.contact-form input::-webkit-input-placeholder {
  color: #aaaab3;
}

.contact-form input::-moz-placeholder {
  color: #aaaab3;
}

.contact-form input:-ms-input-placeholder {
  color: #aaaab3;
}

.contact-form input::-ms-input-placeholder {
  color: #aaaab3;
}

.contact-form input::placeholder {
  color: #aaaab3;
}

.contact-form textarea {
  width: 100%;
  height: 150px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #aaaab3;
  padding-left: 25px;
  padding-top: 12px;
  margin-bottom: 33px;
  resize: none;
}

.contact-form textarea::-webkit-input-placeholder {
  color: #aaaab3;
}

.contact-form textarea::-moz-placeholder {
  color: #aaaab3;
}

.contact-form textarea:-ms-input-placeholder {
  color: #aaaab3;
}

.contact-form textarea::-ms-input-placeholder {
  color: #aaaab3;
}

.contact-form textarea::placeholder {
  color: #aaaab3;
}

.contact-form button {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 2px;
  background: #dfa974;
  border: none;
  padding: 14px 34px 13px;
  display: inline-block;
}

.map {
  height: 470px;
  -webkit-box-shadow: 0px 14px 35px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 14px 35px rgba(0, 0, 0, 0.15);
  margin-top: 75px;
}

.map iframe {
  width: 100%;
}



/*-------------------------------- Respinsive Media Styles --------------------------------*/

@media only screen and (min-width: 1554px) and (max-width: 1920px) {
  .hp-room-items .hp-room-item .hr-text {
    bottom: -200px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .hp-room-items .hp-room-item .hr-text {
    bottom: -288px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

/* Medium Device: 1200px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-slider.owl-carousel .owl-nav button {
    left: -150px;
  }

  .testimonial-slider.owl-carousel .owl-nav button.owl-next {
    right: -150px;
  }

  .hp-room-items .hp-room-item .hr-text {
    bottom: -322px;
  }

  .hp-room-items .hp-room-item:hover .hr-text {
    bottom: 80px;
  }
}

@media only screen and (max-width: 991px) {
  .top-nav {
    display: none;
  }

  .menu-item .nav-menu {
    display: none;
  }

  .offcanvas-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 98;
    height: 100%;
    width: 100%;
    visibility: hidden;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .offcanvas-menu-overlay.active {
    visibility: visible;
  }

  .canvas-open {
    position: absolute;
    right: 40px;
    top: 25px;
    font-size: 22px;
    width: 30px;
    height: 30px;
    color: #19191a;
    border: 1px solid #19191a;
    border-radius: 2px;
    line-height: 33px;
    text-align: center;
    z-index: 100;
    display: block;
    cursor: pointer;
  }

  .offcanvas-menu-wrapper {
    position: fixed;
    /* left: -300px; */
    top: 0;
    width: 300px;
    z-index: 999;
    background: #ffffff;
    text-align: center;
    overflow-y: auto;
    height: 100%;
    /* opacity: 0; */
    /* visibility: hidden; */
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    padding: 80px 30px 30px 30px;
    display: block;
  }

  .offcanvas-menu-wrapper .canvas-close {
    height: 30px;
    width: 30px;
    border: 1px solid #19191a;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
    padding-right: 1px;
  }

  .offcanvas-menu-wrapper .search-icon {
    color: #19191a;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .offcanvas-menu-wrapper .header-configure-area {
    margin-bottom: 30px;
  }

  .offcanvas-menu-wrapper .header-configure-area .language-option {
    display: inline-block;
    cursor: pointer;
    padding: 13px 0;
    position: relative;
    margin-bottom: 10px;
  }

  .offcanvas-menu-wrapper .header-configure-area .language-option:hover .flag-dropdown {
    top: 40px;
    opacity: 1;
    visibility: visible;
  }

  .offcanvas-menu-wrapper .header-configure-area .language-option img {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .offcanvas-menu-wrapper .header-configure-area .language-option span {
    font-size: 16px;
    color: #19191a;
    text-transform: uppercase;
    font-weight: 500;
  }

  .offcanvas-menu-wrapper .header-configure-area .language-option .flag-dropdown {
    position: absolute;
    left: 0;
    top: 60px;
    width: 100px;
    background: #ffffff;
    display: block;
    padding: 10px 15px;
    -webkit-box-shadow: 0px 9px 10px rgba(25, 25, 26, 0.2);
    box-shadow: 0px 9px 10px rgba(25, 25, 26, 0.2);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 99;
  }

  .offcanvas-menu-wrapper .header-configure-area .language-option .flag-dropdown ul li {
    list-style: none;
    text-align: left;
  }

  .offcanvas-menu-wrapper .header-configure-area .language-option .flag-dropdown ul li a {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: #19191a;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .offcanvas-menu-wrapper .header-configure-area .language-option .flag-dropdown ul li a:hover {
    color: #dfa974;
  }

  .offcanvas-menu-wrapper .header-configure-area .bk-btn {
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    padding: 16px 28px 15px;
    background: #dfa974;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .offcanvas-menu-wrapper .mainmenu {
    display: block;
  }

  .offcanvas-menu-wrapper .slicknav_menu {
    background: transparent;
    padding: 0;
  }

  .offcanvas-menu-wrapper .slicknav_menu .slicknav_btn {
    float: none;
    margin: 0 0 10px 0;
    display: none;
  }

  .offcanvas-menu-wrapper .slicknav_menu .slicknav_nav {
    display: block !important;
  }

  .offcanvas-menu-wrapper .slicknav_menu .slicknav_nav ul {
    margin: 0;
    text-align: left;
  }

  .offcanvas-menu-wrapper .slicknav_menu .slicknav_nav ul li .dropdown {
    padding-left: 20px;
  }

  .offcanvas-menu-wrapper .slicknav_menu .slicknav_nav ul li a {
    margin: 0;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #19191a;
    border-bottom: 1px solid #e5e5e5;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .offcanvas-menu-wrapper .slicknav_menu .slicknav_nav ul li a:hover {
    border-radius: 0;
    background: transparent;
    color: #dfa974;
  }

  .offcanvas-menu-wrapper .slicknav_menu .slicknav_nav .slicknav_row a {
    border-bottom: 0;
    padding-left: 0;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .offcanvas-menu-wrapper .slicknav_menu .slicknav_nav .slicknav_row span {
    color: #19191a;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .offcanvas-menu-wrapper .slicknav_menu .slicknav_nav .slicknav_row:hover a {
    color: #dfa974;
  }

  .offcanvas-menu-wrapper .slicknav_menu .slicknav_nav .slicknav_row:hover span {
    color: #dfa974;
  }

  .mobile-menu {
    width: 100%;
    margin: 0;
    text-align: left;
  }

  .mobile-menu ul li .dropdown {
    padding-left: 20px;
  }

  .mobile-menu ul {
    list-style: none;
    width: 100%;
  }

  .mobile-menu ul li a {
    display: block;
    margin: 0;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #19191a;
    border-bottom: 1px solid #e5e5e5;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }

  .mobile-menu ul li a:hover {
    border-radius: 0;
    background: transparent;
    color: #dfa974;
  }

  .offcanvas-menu-wrapper .top-social {
    display: inline-block;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .offcanvas-menu-wrapper .top-social a {
    display: inline-block;
    font-size: 16px;
    color: #19191a;
    margin-right: 20px;
  }

  .offcanvas-menu-wrapper .top-social a:last-child {
    margin-right: 0;
  }

  .offcanvas-menu-wrapper .top-widget li {
    list-style: none;
    font-size: 16px;
    color: #19191a;
    font-weight: 500;
    line-height: 28px;
  }

  .offcanvas-menu-wrapper .top-widget li i {
    color: #dfa974;
    margin-right: 4px;
  }

  .offcanvas-menu-wrapper.show-offcanvas-menu-wrapper {
    left: 0;
    opacity: 1;
    visibility: visible;
  }

  .about-text {
    margin-bottom: 30px;
  }

  .footer-section .copyright-option ul {
    text-align: center;
    margin-bottom: 10px;
  }

  .footer-section .copyright-option .co-text {
    text-align: center;
  }

  .about-pic img {
    margin-bottom: 20px;
  }

  .room-booking {
    padding: 0;
    margin-top: 40px;
  }

  .contact-form {
    padding-top: 30px;
  }

  .hero-text {
    margin-bottom: 40px;
  }
}

/* Tablet Device: 768px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-slider.owl-carousel .owl-nav button {
    left: -30px;
  }

  .testimonial-slider.owl-carousel .owl-nav button.owl-next {
    right: -30px;
  }

  .hp-room-items {
    margin: 0;
  }

  .hp-room-item {
    margin-bottom: 15px;
  }
}

/* Large Mobile: 480px */

@media only screen and (max-width: 767px) {
  .testimonial-slider {
    display: block;
    margin: 0;
  }

  .testimonial-slider.owl-carousel .owl-nav {
    display: none;
  }

  .room-details-item .rd-text .rd-title {
    margin-bottom: 25px;
  }

  .room-details-item .rd-text .rd-title h3 {
    float: none;
    margin-bottom: 30px;
  }

  .room-details-item .rd-text .rd-title .rdt-right {
    float: none;
    text-align: left;
  }

  .footer-section .copyright-option ul li {
    margin-right: 25px;
  }

  .blog-details-text .tag-share .tags {
    float: none;
    margin-bottom: 20px;
  }

  .blog-details-text .tag-share .social-share {
    float: none;
  }

  .about-text .section-title h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .hp-room-items {
    margin: 0 -5px;
  }

  .hp-room-item {
    margin-bottom: 10px;
  }

  .carousel-item {
    min-height: 300px;
    background-color: red;
  }

  .carousel-item img {
    width: 100%;
    height: 300px;
  }

  .hero-text {
    padding-top: 15px;
    text-align: justify;
  }

  .hero-text h1 {
    font-size: 25px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 16px;
    text-align: left;
  }

  .hero-text p {
    font-size: 15px;
    color: #ffffff;
    line-height: 13px;
    margin-bottom: 15px;
    text-align: left
  }
}

/* Small Mobile: 320px */

@media only screen and (max-width: 479px) {
  .footer-section .copyright-option ul li {
    margin-bottom: 5px;
  }

  .video-section {
    padding-top: 80px;
  }

  .rd-reviews .review-item .ri-pic,
  .blog-details-text .comment-option .single-comment-item .sc-author {
    float: none;
    margin-bottom: 30px;
  }

  .rd-reviews .review-item .ri-text .rating {
    position: relative;
  }

  .rd-reviews .review-item .ri-text,
  .blog-details-text .comment-option .single-comment-item.second-comment .sc-text,
  .blog-details-text .comment-option .single-comment-item.first-comment .sc-text {
    padding-left: 0;
  }

  .blog-details-text .comment-option .single-comment-item.reply-comment {
    padding-left: 30px;
  }

  .rd-reviews .review-item .ri-text:before,
  .blog-details-text .comment-option .single-comment-item.first-comment .sc-text:before,
  .blog-details-text .comment-option .single-comment-item.second-comment .sc-text:before {
    display: none;
  }

  .blog-details-hero {
    padding-top: 70px;
  }

  .blog-details-text .bd-pic .bp-item {
    width: 100%;
    float: none;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .blog-details-text .tag-share .tags a {
    margin-bottom: 6px;
  }

  .blog-details-text .comment-option .single-comment-item .sc-text a {
    margin-bottom: 10px;
  }

  .carousel-item {
    min-height: 300px;
    background-color: red;
  }

  .carousel-item img {
    width: 100%;
    height: 300px;
  }

  .hero-text {
    padding-top: 15px;
    text-align: justify;
  }

  .hero-text h1 {
    font-size: 25px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 16px;
    text-align: left;
  }

  .hero-text p {
    font-size: 15px;
    color: #ffffff;
    line-height: 13px;
    margin-bottom: 15px;
    text-align: left
  }
}